import React, { useEffect } from "react";
import AcousticLevitation from "./anticounterclockwise/force fields/point charges/AcousticLevitation";
import CuboidCrystal from "./anticounterclockwise/force fields/point charges/CuboidCrystal";
import MagneticPendulums from "./anticounterclockwise/force fields/point charges/MagneticPendulums";
import StriationsOfMuscleFibers from "./anticounterclockwise/force fields/point charges/StriationsOfMuscleFibers";
import SineWaves from "./anticounterclockwise/SineWaves";
import "./App.css";
import CircleArt from "./drawings/CircleArt";
import CircleArtStatic from "./drawings/CircleArtStatic";
import ForceFields from "./drawings/ForceFields";
import HeyJewelGirl from "./drawings/HeyJewelGirl";
import LandingPageWithPointilistVignette from "./drawings/LandingPageWithPointilistVignette";
import LandingPageWithPointilistVignetteCenterOn from "./drawings/LandingPageWithPointilistVignetteCenterOn";
import Landing from "./pixelspace/Landing";
import Landing2 from "./pixelspace/Landing2";
import Landing3 from "./pixelspace/Landing3";
import PixelSpace from "./pixelspace/PixelSpace";
import PixelOnboarding from "./pixel_onboarding/PixelOnboarding";
import Shaders from "./shaders/Shaders";
import Stage from "./stage/Stage";

function App() {
  // return <PixelSpace />;
  // return <Stage />;
  // return <Shaders />;
  // return <PixelOnboarding />;
  //   return <HeyJewelGirl />;
  // return <LandingPageWithPointilistVignetteCenterOn />;
  // return <ForceFields />;
  // return <CuboidCrystal />;
  return <MagneticPendulums />;
  // return <AcousticLevitation />;
  // return <StriationsOfMuscleFibers />;
}

export default App;
