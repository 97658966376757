// INCOMPLETE
//
// GOAL:
//
// Make a point charge
// Attract an attractable object
// Repel a repellable object
//

//
// To make a point charge
// To attract a point charge
// To repel a point charge
// To make a field of non-attractor attractable objects.

import { useCallback, useEffect, useRef, useState } from "react";
import {
  BoxGeometry,
  DoubleSide,
  Mesh,
  MeshPhongMaterial,
  PerspectiveCamera,
  PlaneGeometry,
  PointLight,
  Scene,
  ShaderMaterial,
  Vector2,
  Vector3,
  WebGLRenderer,
} from "three";

export default function FascinatingAnimation() {
  return (
    <div>
      <div
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <h1>
          Mathematics requires a small dose, not of genius, but of an <br />
          imaginative freedom which, in a larger dose, would be insanity
        </h1>
        <p>-Angus K. Rodgers</p>
      </div>
      <Composer />
    </div>
  );
}

function Composer() {
  const canvas = useRef(null);

  const mouse = useMouse();

  useEffect(() => {
    if (canvas.current == null) return;

    // mount
    let composable = [ThreeScene(canvas.current, mouse)];
    composable.forEach((el) => el.mount());

    // loop
    let rafID: number;
    const tick = () => {
      const t = Date.now();
      composable.forEach((el) => el.loop({ t, mouse }));
      rafID = requestAnimationFrame(tick);
    };
    tick();

    // unmount
    return () => {
      composable.forEach((el) => el.unmount());
      cancelAnimationFrame(rafID);
    };
  }, [canvas.current]);

  return (
    <canvas
      ref={canvas}
      width={window.innerWidth}
      height={window.innerHeight}
    />
  );
}

type Composable = {
  mount: () => void;
  loop: ({ t, mouse }: { t: number; mouse: { x: number; y: number } }) => void;
  unmount: () => void;
};

function ThreeScene(canvas: HTMLCanvasElement, mouse: any): Composable {
  let scene: Scene | null = null;
  let camera: PerspectiveCamera | null = null;
  let renderer: WebGLRenderer | null = null;

  let composable: Composable[] = [];

  return {
    mount: () => {
      scene = new Scene();
      camera = new PerspectiveCamera(
        10,
        window.innerWidth / window.innerHeight
      );
      camera.position.z = 70;
      camera.lookAt(0, 0, 0);
      //   camera.rotation.z = 0;

      renderer = new WebGLRenderer({ canvas });

      composable.push(Lights(scene));
      composable.push(SimplePlane(scene));

      composable.forEach((el) => el.mount());
    },
    loop: ({ t, mouse }: { t: number; mouse: { x: number; y: number } }) => {
      composable.forEach((el) => el.loop({ t, mouse }));
      if (scene && camera && renderer) {
        renderer.render(scene, camera);
        // console.log(scene.getObjectByName("findme"));
      }
    },
    unmount: () => {
      composable.forEach((el) => el.unmount());
    },
  };
}

function Lights(scene: Scene): Composable {
  return {
    mount: () => {
      let point = new PointLight(0x33ff99, 1, 10);
      point.position.z = 4;
      scene.add(point);
    },
    loop: ({ t }: { t: number }) => {},
    unmount: () => {},
  };
}

function SimpleBox(scene: Scene): Composable {
  let box: Mesh | null = null;
  return {
    mount: () => {
      let geometry = new BoxGeometry(1, 1, 1, 100, 100, 100);
      let material = new MeshPhongMaterial({ shininess: 1 });
      box = new Mesh(geometry, material);
      box.name = "findme";
      scene.add(box);
    },
    loop: ({ t }: { t: number }) => {
      box?.rotation.set(Math.sin(t / 1000), t / 1000, 0);
    },
    unmount: () => {},
  };
}

function useMouse() {
  const [mouse, setMouse] = useState({ x: 0, y: 0 });
  useEffect(() => {
    window.addEventListener("mousemove", updateMouse);
    return () => {
      window.removeEventListener("mousemove", updateMouse);
    };
  }, []);

  const updateMouse = useCallback((ev: MouseEvent) => {
    setMouse({
      x: ev.pageX,
      y: ev.pageY,
    });
  }, []);

  return mouse;
}

function SimplePlane(scene: Scene): Composable {
  let plane: Mesh | null = null;

  let mouse = { x: window.innerWidth / 2, y: window.innerHeight / 2 };
  const updateMouse = (ev: MouseEvent) => {
    mouse = { x: ev.clientX, y: ev.clientY };
  };
  return {
    mount: () => {
      window.addEventListener("mousemove", updateMouse as EventListener);
      let geometry = new PlaneGeometry(10, 10, 200, 200);
      let material = new ShaderMaterial({
        uniforms: {
          time: { value: new Vector3(1.0, 1.0, 1.0) },
          mouse: {
            value: new Vector2(window.innerWidth / 2, window.innerHeight / 2),
          },
        },
        side: DoubleSide,
        vertexShader: `
            uniform vec3 time;
            uniform vec2 mouse;
            varying vec4 pos;
            varying vec2 _mouse;

            void main() {
                float x =  mouse.x*20.0+ position.x; //position.x;
                float y = mouse.y*20.0+ position.y; //position.y;
                float z = sin(x*10.) + cos(y*10.) + 10.0*time.x;//sin(y+64.6+time.x*10.0); //position.x;

                // gl_Position = projectionMatrix * modelViewMatrix * vec4(x / 10.0, mod(x, 10.0) / 10.0, mod(x, 10.0) / 10.0  +  mod(y, 100.0) / 100.0, 1.0 );
                gl_Position = projectionMatrix * modelViewMatrix * vec4(x, y, z, 1.0);
                pos = gl_Position;
                _mouse = mouse;
            }
          `,
        fragmentShader: `
            varying vec4 pos;
            varying vec2 _mouse;

            void main() {
                vec4 adjpos = pos;
                float intensity = distance(sin(pos), cos(adjpos));
                float red = 1.0-distance(vec2(_mouse.x * 50.0 - 25.0, 0.0), vec2(pos.x, pos.y));
                float green = 1.0-distance(vec2(0.0, _mouse.y*20.0-10.0), vec2(pos.x, pos.y));
                gl_FragColor = vec4(
                    red, 
                    green,
                    intensity,
                    1.0-intensity
                );
            }
          `,
        wireframe: true,
      });
      plane = new Mesh(geometry, material);
      scene.add(plane);
    },
    loop: ({ t }) => {
      let { x, y } = mouse;
      //   plane?.rotation.set(Math.sin(t / 1000), 0, 0);
      if (!plane) return;
      plane.rotation.set(0, 0, 0);
      // plane.position.z = Math.sin(mouse.x / 1000);
      //   plane.rotation.set(Math.max.
      (plane.material as ShaderMaterial).uniforms["time"].value = new Vector3(
        Math.sin(t / 1000),
        mouse.x / window.innerWidth - 0.5,
        mouse.y / window.innerHeight - 0.5
      );
      //   console.log(mouse.x, mouse.y);
      (plane.material as ShaderMaterial).uniforms["mouse"].value = new Vector2(
        mouse.x / window.innerWidth - 0.5,
        mouse.y / window.innerHeight - 0.5
      );
    },
    unmount: () => {
      window.removeEventListener("mousemove", updateMouse);
    },
  };
}
